/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* // #44acff, #0056e1 */
html {
  scroll-behavior: smooth;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #636363 #fff;
}

.clip-triangle {
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
}

.hr-process {
  text-align: center;
}

.circle-process {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 500px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.center-text {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.step {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3f2fd;
  text-align: center;
  font-size: 14px;
}
.hero-title {
  margin-top: auto; /* Pushes the title towards the bottom */
  margin-bottom: 10vh; /* Adjusts the distance from the bottom */
}